// import React, { useState } from 'react';
// import Button from '../../elements/button';
// import Card from '../../elements/card';
// import Heading, { SmallText, Subheading } from '../../elements/text';
// import Input from '../../elements/inputs';
// import styles from './signin.module.scss';
// import Link from 'next/link';
// import { google } from '../../../assets/icons';
// import FormDivider from '../../elements/form-divider';
// import MainLink from '../../elements/link';
// import { Formik, ErrorMessage } from 'formik';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   loginPending,
//   loginSuccess,
//   loginFail,
//   login,
// } from '../../../store/auth/loginSlice';
// import { signInValidationSchema } from '../../../utils';
// import { loginUser } from '../../../api-client/signin';
// import { toast } from 'react-toastify';
// import Router, { useRouter } from 'next/router';
// import { eyeOff, eye as eyeIcon } from '../../../public/assets/icons';
// import Image from 'next/image';
// import Authlayout from '../auth-layout';

// const SignInWidget = ({ userGeo }) => {
//   const dispatch = useDispatch();
//   const authState = useSelector(login);
//   const router = useRouter();
//   const [eye, setEye] = useState(false);
//   return (
  

//     <section className="">
//       <div className=" bg-white">
//         <Card className="mt-10   ">
//           <header className="mb-8">
//             <Heading >
//               <p className=" text-left  font-[600] text-[#061938] lg:mt-0 mt-5  lg:text-[30px] leading-[30px] text-[20px] ">

//               Welcome Back!
//               </p>
//               </Heading>
//             <Subheading>
//               <p className='text-left py-2  text-[11px] lg:text-[16px]'>

//               {"Don't have an Account?"}
//               <MainLink href="/auth/sign-up" text=" Sign Up" />
//               </p>
//             </Subheading>
//           </header>
//           {/* <Button
//             className="btn-primary mt-4 mb-4 py-5 px-4 w-full"
//             btnIcon={google}
//             label="Continue with Google"
//           /> */}
//           {/* <FormDivider /> */}
//           <Formik
//             // Initialise Initial Form Values
//             initialValues={{ email: '', password: '' }}
//             // Function for Error message Display
//             validationSchema={signInValidationSchema}
//             // Submit Function
//             onSubmit={async (data) => {
//               dispatch(loginPending());

//               const formData = {
//                 ...data,
//                 geo: userGeo,
//               };

//               try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 router.push('/dashboard/affiliate');
//               } catch (error) {
//                 toast.error(error.response.data.message);

//                 if (error.response.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }

//                 dispatch(loginFail());
//               }
//             }}
//           >
//             {({ values, handleChange, handleSubmit, errors, touched }) => (
//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//               <p className='text-[#061938] leading-[20px] text-[10px] lg:text-[13px] '>Email Address</p>
//                 <Input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   onChange={handleChange}
//                   error={errors.email && touched.email}
//                   value={values.email}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5]  "
                 
                
//                 />
//                 <ErrorMessage name="email">
//                   {(msg) => (
//                     <SmallText>
//                       <p className="error-text"> {msg}</p>
//                     </SmallText>
//                   )}
//                 </ErrorMessage>

//               <p className='text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4 '>Password</p>

//                 <Input
//                   type={eye ? 'text' : 'password'}
//                   icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
//                   onPasswordToggle={() => setEye(!eye)}
//                   placeholder="Password"
//                   name="password"
//                   error={errors.password && touched.password}
//                   value={values.password}
//                   onChange={handleChange}
//                   className="border-[1px]  rounded-[8px] border-[#B3BAC5] h-[60px]"
          

//                 />
//                 <ErrorMessage name="password">
//                   {(msg) => <p className="error-text text-xs">{msg}</p>}
//                 </ErrorMessage>
//                 <Button
//                   className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
//                   label="Log In"
//                   type="submit"
//                   loading={authState.isLoading}
//                 />
//                   <p
//                     className="  text-[10px] lg:text-[12px] leading-[20px] font-[400]"
//                     style={{
//                       color: '#505F79',
                     
//                       textAlign: 'left',
//                       cursor: 'pointer',
//                       marginTop: "8px"
//                     }}
//                   >

//                     Forgot Password ? {" "}
//                 <Link href="/auth/reset-password" className="" passHref>
//                 <span className='text-[#58D96B]'>

//                Click to Reset Password
//                 </span>
//                 </Link>
//                   </p>
//               </form>
//             )}
//           </Formik>
//         </Card>
//       </div>
//     </section>
   
//   );
// };

// export default SignInWidget;
import React, { useEffect, useState } from 'react';
import Button from '../../elements/button';
import Card from '../../elements/card';
import Heading, { SmallText, Subheading } from '../../elements/text';
import Input from '../../elements/inputs';
import styles from './signin.module.scss';
import Link from 'next/link';
import { google } from '../../../assets/icons';
import MainLink from '../../elements/link';
import { Formik, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  loginPending,
  loginSuccess,
  loginFail,
  login,
} from '../../../store/auth/loginSlice';
import { signInValidationSchema } from '../../../utils';
import { loginUser } from '../../../api-client/signin';
import { toast } from 'react-toastify';
import Router, { useRouter } from 'next/router';
import { eyeOff, eye as eyeIcon } from '../../../public/assets/icons';
import Image from 'next/image';
import Authlayout from '../auth-layout';
import axios from 'axios';
import Cookie from "universal-cookie";

// const SignInWidget = ({ userGeo }) => {
//   const cookies = new Cookie();
  
//   const dispatch = useDispatch();

//   const authState = useSelector(login);
//   const router = useRouter();
//   const [eye, setEye] = useState(false);

//   const checkOnboardingProgress = async () => {
//     try {
//       const res = await axios.get(
//         axios.get(`${process.env.NEXT_PUBLIC_API_URL}/users/checkOnboardingProgress`, {
//           headers: { Authorization: `Bearer ${authToken}` },
//         }),
//       );
//       return res.data.completed; // Assuming the API returns a "completed" field indicating onboarding status
//     } catch (error) {
//       toast.error('Failed to check onboarding progress');
//       return false;
//     }
//   };

//   return (
//     <section className="">
//       <div className="bg-white">
//         <Card className="mt-10">
//           <header className="mb-8">
//             <Heading>
//               <p className="text-left font-[600] text-[#061938] lg:mt-0 mt-5 lg:text-[30px] leading-[30px] text-[20px]">
//                 Welcome Back!
//               </p>
//             </Heading>
//             <Subheading>
//               <p className="text-left py-2 text-[11px] lg:text-[16px]">
//                 {"Don't have an Account?"}
//                 <MainLink href="/auth/sign-up" text=" Sign Up" />
//               </p>
//             </Subheading>
//           </header>

//           <Formik
//             initialValues={{ email: '', password: '' }}
//             validationSchema={signInValidationSchema}
//             onSubmit={async (data) => {
//               dispatch(loginPending());

//               const formData = {
//                 ...data,
//                 geo: userGeo,
//               };

//               try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 // After login, check onboarding progress
//                 const onboardingCompleted = await checkOnboardingProgress();
                
//                 if (onboardingCompleted) {
//                   router.push('/dashboard/affiliate');
//                 } else {
//                   router.push('/stepper'); // Redirect to stepper if onboarding is not complete
//                 }
//               } catch (error) {
//                 toast.error(error.response?.data?.message || 'An error occurred');
//                 if (error.response?.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }
//                 dispatch(loginFail());
//               }
//             }}
//           >
//             {({ values, handleChange, handleSubmit, errors, touched }) => (
//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px]">
//                   Email Address
//                 </p>
//                 <Input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   onChange={handleChange}
//                   error={errors.email && touched.email}
//                   value={values.email}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5]"
//                 />
//                 <ErrorMessage name="email">
//                   {(msg) => (
//                     <SmallText>
//                       <p className="error-text"> {msg}</p>
//                     </SmallText>
//                   )}
//                 </ErrorMessage>

//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4">
//                   Password
//                 </p>

//                 <Input
//                   type={eye ? 'text' : 'password'}
//                   icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
//                   onPasswordToggle={() => setEye(!eye)}
//                   placeholder="Password"
//                   name="password"
//                   error={errors.password && touched.password}
//                   value={values.password}
//                   onChange={handleChange}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5] h-[60px]"
//                 />
//                 <ErrorMessage name="password">
//                   {(msg) => <p className="error-text text-xs">{msg}</p>}
//                 </ErrorMessage>

//                 <Button
//                   className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
//                   label="Log In"
//                   type="submit"
//                   loading={authState.isLoading}
//                 />
//                 <p
//                   className="text-[10px] lg:text-[12px] leading-[20px] font-[400]"
//                   style={{
//                     color: '#505F79',
//                     textAlign: 'left',
//                     cursor: 'pointer',
//                     marginTop: '8px',
//                   }}
//                 >
//                   Forgot Password ?{' '}
//                   <Link href="/auth/reset-password" className="" passHref>
//                     <span className="text-[#58D96B]">Click to Reset Password</span>
//                   </Link>
//                 </p>
//               </form>
//             )}
//           </Formik>
//         </Card>
//       </div>
//     </section>
//   );
// };

// export default SignInWidget;


// const SignInWidget = ({ userGeo }) => {
//   const dispatch = useDispatch();
//   const authState = useSelector(login);
//   const router = useRouter();
//   const [eye, setEye] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state

//   const checkOnboardingProgress = async (authToken) => {
//     try {
//       const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/users/checkOnboardingProgress`, {
//         headers: { Authorization: `Bearer ${authToken}` },
//       });
//       return res.data.completed; // Assuming the API returns a "completed" field
//     } catch (error) {
//       toast.error('Failed to check onboarding progress');
//       return false;
//     }
//   };

//   return (
//     <section className="">
//       <div className="bg-white">
//         <Card className="mt-10">
//           <header className="mb-8">
//             <Heading>
//               <p className="text-left font-[600] text-[#061938] lg:mt-0 mt-5 lg:text-[30px] leading-[30px] text-[20px]">
//                 Welcome Back!
//               </p>
//             </Heading>
//             <Subheading>
//               <p className="text-left py-2 text-[11px] lg:text-[16px]">
//                 {"Don't have an Account?"}
//                 <MainLink href="/auth/sign-up" text=" Sign Up" />
//               </p>
//             </Subheading>
//           </header>

//           <Formik
//             initialValues={{ email: '', password: '' }}
//             validationSchema={signInValidationSchema}
//             onSubmit={async (data) => {
//               dispatch(loginPending());

//               const formData = {
//                 ...data,
//                 geo: userGeo,
//               };

//               {/* try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 // Set loading state while checking onboarding progress
//                 setLoading(true);

//                 const onboardingCompleted = await checkOnboardingProgress(res.data.authToken);

//                 if (onboardingCompleted) {
//                   router.push('/dashboard/affiliate');
//                 } else {
//                   router.push('/stepper'); // Redirect to stepper if onboarding is not complete
//                 }
//               } catch (error) {
//                 toast.error(error.response?.data?.message || 'An error occurred');
//                 if (error.response?.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }
//                 dispatch(loginFail());
//               } finally {
//                 setLoading(false); // Reset loading state
//               }
//             }}
//           > */}
//           try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 // Set loading state while checking onboarding progress
//                 setLoading(true);

//                 const onboardingProgress = await checkOnboardingProgress(res.data.authToken);

//                 if (onboardingProgress) {
//                   const { totalQuestions, answeredQuestions } = onboardingProgress;

//                   // Check if all questions have been answered
//                   if (totalQuestions === answeredQuestions) {
//                     router.push('/dashboard/affiliate'); // Redirect to the affiliate dashboard
//                   } else {
//                     router.push('/stepper'); // Redirect to the stepper if onboarding is not complete
//                   }
//                 } else {
//                   router.push('/stepper'); // Default to stepper if the progress check failed
//                 }
//               } catch (error) {
//                 toast.error(error.response?.data?.message || 'An error occurred');
//                 if (error.response?.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }
//                 dispatch(loginFail());
//               } finally {
//                 setLoading(false); // Reset loading state
//               }
//             }}
//           >
//             {({ values, handleChange, handleSubmit, errors, touched }) => (
//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px]">Email Address</p>
//                 <Input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   onChange={handleChange}
//                   error={errors.email && touched.email}
//                   value={values.email}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5]"
//                 />
//                 <ErrorMessage name="email">
//                   {(msg) => (
//                     <SmallText>
//                       <p className="error-text">{msg}</p>
//                     </SmallText>
//                   )}
//                 </ErrorMessage>

//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4">Password</p>
//                 <Input
//                   type={eye ? 'text' : 'password'}
//                   icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
//                   onPasswordToggle={() => setEye(!eye)}
//                   placeholder="Password"
//                   name="password"
//                   error={errors.password && touched.password}
//                   value={values.password}
//                   onChange={handleChange}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5] h-[60px]"
//                 />
//                 <ErrorMessage name="password">
//                   {(msg) => <p className="error-text text-xs">{msg}</p>}
//                 </ErrorMessage>

//                 <Button
//                   className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
//                   label={loading ? "Loading..." : "Log In"} // Change button text based on loading state
//                   type="submit"
//                   loading={authState.isLoading}
//                   disabled={loading} // Disable button while loading
//                 />
//                 <p
//                   className="text-[10px] lg:text-[12px] leading-[20px] font-[400]"
//                   style={{
//                     color: '#505F79',
//                     textAlign: 'left',
//                     cursor: 'pointer',
//                     marginTop: '8px',
//                   }}
//                 >
//                   Forgot Password ?{' '}
//                   <Link href="/auth/reset-password" passHref>
//                     <span className="text-[#58D96B]">Click to Reset Password</span>
//                   </Link>
//                 </p>
//               </form>
//             )}
//           </Formik>
//         </Card>
//       </div>
//     </section>
//   );
// };

// export default SignInWidget;
// const SignInWidget = ({ userGeo }) => {
//   const dispatch = useDispatch();
//   const authState = useSelector(login);
//   const router = useRouter();
//   const [eye, setEye] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state for login submission
//   const [checkingOnboarding, setCheckingOnboarding] = useState(false); // New loading state for onboarding check

//   const checkOnboardingProgress = async (authToken) => {
//     try {
//       const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/users/checkOnboardingProgress`, {
//         headers: { Authorization: `Bearer ${authToken}` },
//       });

//       // Log the API response to ensure it's correct
//       console.log('Onboarding Response:', res.data);

//       // Check if onboarding is completed based on the "redirect" field in "data"
//       if (res.data.success && res.data.data?.redirect === '/users/dashboard') {
//         return true; // Onboarding is completed
//       } else {
//         return false; // Onboarding is not completed
//       }
//     } catch (error) {
//       console.log('Failed to check onboarding progress');
//       return false; // Default to not completed if there's an error
//     }
//   };

//   return (
//     <section className="">
//       <div className="bg-white">
//         <Card className="mt-10">
//           <header className="mb-8">
//             <Heading>
//               <p className="text-left font-[600] text-[#061938] lg:mt-0 mt-5 lg:text-[30px] leading-[30px] text-[20px]">
//                 Welcome Back!
//               </p>
//             </Heading>
//             <Subheading>
//               <p className="text-left py-2 text-[11px] lg:text-[16px]">
//                 {"Don't have an Account?"}
//                 <MainLink href="/auth/sign-up" text=" Sign Up" />
//               </p>
//             </Subheading>
//           </header>

//           <Formik
//             initialValues={{ email: '', password: '' }}
//             validationSchema={signInValidationSchema}
//             onSubmit={async (data) => {
//               dispatch(loginPending());

//               const formData = {
//                 ...data,
//                 geo: userGeo,
//               };

//               try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 // Start loading for the onboarding check
//                 setLoading(true);
//                 setCheckingOnboarding(true);

//                 // Check if onboarding is completed
//                 const onboardingCompleted = await checkOnboardingProgress(res.data.authToken);

//                 // Log onboarding completion status
//                 console.log('Onboarding Completed:', onboardingCompleted);

//                 if (onboardingCompleted) {
//                   // Redirect directly to affiliate dashboard if onboarding is completed
//                   router.push('/dashboard/affiliate');
//                 } else {
//                   // Redirect to stepper if onboarding is not completed
//                   router.push('/stepper');
//                 }
//               } catch (error) {
//                 toast.error(error.response?.data?.message || 'An error occurred');
//                 if (error.response?.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }
//                 dispatch(loginFail());
//               } finally {
//                 setLoading(false); // Reset loading state after login and onboarding check
//                 setCheckingOnboarding(false); // Stop the onboarding check loading spinner
//               }
//             }}
//           >
//             {({ values, handleChange, handleSubmit, errors, touched }) => (
//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px]">Email Address</p>
//                 <Input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   onChange={handleChange}
//                   error={errors.email && touched.email}
//                   value={values.email}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5]"
//                 />
//                 <ErrorMessage name="email">
//                   {(msg) => (
//                     <SmallText>
//                       <p className="error-text">{msg}</p>
//                     </SmallText>
//                   )}
//                 </ErrorMessage>

//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4">Password</p>
//                 <Input
//                   type={eye ? 'text' : 'password'}
//                   icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
//                   onPasswordToggle={() => setEye(!eye)}
//                   placeholder="Password"
//                   name="password"
//                   error={errors.password && touched.password}
//                   value={values.password}
//                   onChange={handleChange}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5] h-[60px]"
//                 />
//                 <ErrorMessage name="password">
//                   {(msg) => <p className="error-text text-xs">{msg}</p>}
//                 </ErrorMessage>

//                 {/* Display loader if checking onboarding */}
//                 {checkingOnboarding ? (
//                   <div className="loading-spinner">
//                     {/* Customize this spinner according to your UI framework */}
//                     <p>Checking your onboarding status...</p>
//                   </div>
//                 ) : (
//                   <Button
//                     className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
//                     label={loading ? "Loading..." : "Log In"} // Change button text based on loading state
//                     type="submit"
//                     loading={authState.isLoading}
//                     disabled={loading} // Disable button while loading
//                   />
//                 )}

//                 <p
//                   className="text-[10px] lg:text-[12px] leading-[20px] font-[400]"
//                   style={{
//                     color: '#505F79',
//                     textAlign: 'left',
//                     cursor: 'pointer',
//                     marginTop: '8px',
//                   }}
//                 >
//                   Forgot Password?{' '}
//                   <Link href="/auth/reset-password" passHref>
//                     <span className="text-[#58D96B]">Click to Reset Password</span>
//                   </Link>
//                 </p>
//               </form>
//             )}
//           </Formik>
//         </Card>
//       </div>
//     </section>
//   );
// };

// export default SignInWidget;
const SignInWidget = ({ userGeo }) => {
  const dispatch = useDispatch();
  const authState = useSelector(state => state.login);
  const router = useRouter();
  const [eye, setEye] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkingOnboarding, setCheckingOnboarding] = useState(false);

  const cookies = new Cookie();

  useEffect(() => {
    // Extract the 'ref' parameter from the query string and store it in cookies if it exists
    const { ref } = router.query;
    if (ref) {
      cookies.set("refID", ref, { path: '/', expires: 30 }); // Store refID for 30 days
    }
  }, [router.query]);

  const checkOnboardingProgress = async (authToken) => {
    try {
      const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/users/checkOnboardingProgress`, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      return res.data.success && res.data.data?.redirect === '/users/dashboard';
    } catch (error) {
      console.log('Failed to check onboarding progress');
      return false;
    }
  };

  return (
    <section>
      <div className="bg-white">
        <Card className="mt-10">
          <header className="mb-8">
            <Heading>
              <p className="text-left font-[600] text-[#061938] lg:mt-0 mt-5 lg:text-[30px] leading-[30px] text-[20px]">
                Welcome Back!
              </p>
            </Heading>
            <Subheading>
              <p className="text-left py-2 text-[11px] lg:text-[16px]">
                {"Don't have an Account?"}
                <MainLink href="/auth/sign-up" text=" Sign Up" />
              </p>
            </Subheading>
          </header>

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={signInValidationSchema}
            onSubmit={async (data) => {
              dispatch(loginPending());

              // Get the refID from cookies if it exists
              const refID = cookies.get("refID");

              // Add the refID only if it exists
              const formData = {
                ...data,
                geo: userGeo,
                refID: refID || null, // Set refID to null if not available
              };

              try {
                setLoading(true); // Set loading state when the form submission begins

                const res = await loginUser(formData);
                dispatch(loginSuccess(res.data));

                setLoading(false); // Reset loading state after successful login

                setCheckingOnboarding(true);

                const onboardingCompleted = await checkOnboardingProgress(res.data.authToken);

                if (onboardingCompleted) {
                  router.push('/dashboard/affiliate');
                } else {
                  router.push('/stepper');
                }
              } catch (error) {
                setLoading(false); // Reset loading state if there's an error
                toast.error(error.response?.data?.message || 'An error occurred');

                if (error.response?.status === 405) {
                  router.push(`/auth/email-verify?email=${data?.email}`);
                }

                dispatch(loginFail());
              } finally {
                setCheckingOnboarding(false); // Reset checking onboarding state
              }
            }}
          >
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px]">Email Address</p>
                <Input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  value={values.email}
                  className="border-[1px] rounded-[8px] border-[#B3BAC5]"
                />
                <ErrorMessage name="email">
                  {(msg) => (
                    <SmallText>
                      <p className="error-text">{msg}</p>
                    </SmallText>
                  )}
                </ErrorMessage>

                <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4">Password</p>
                <Input
                  type={eye ? 'text' : 'password'}
                  icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
                  onPasswordToggle={() => setEye(!eye)}
                  placeholder="Password"
                  name="password"
                  error={errors.password && touched.password}
                  value={values.password}
                  onChange={handleChange}
                  className="border-[1px] rounded-[8px] border-[#B3BAC5] h-[60px]"
                />
                <ErrorMessage name="password">
                  {(msg) => <p className="error-text text-xs">{msg}</p>}
                </ErrorMessage>

                {/* Display loader if checking onboarding */}
                {checkingOnboarding ? (
                  <div className="loading-spinner">
                    {/* <p>Checking your onboarding status...</p> */}
                  </div>
                ) : (
                  <Button
                    className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
                    label={loading ? "Loading..." : "Log In"} // Change button text based on loading state
                    type="submit"
                    loading={authState.isLoading}
                    disabled={loading} // Disable button while loading
                  />
                )}

                <p
                  className="text-[10px] lg:text-[12px] leading-[20px] font-[400]"
                  style={{
                    color: '#505F79',
                    textAlign: 'left',
                    cursor: 'pointer',
                    marginTop: '8px',
                  }}
                >
                  Forgot Password?{' '}
                  <Link href="/auth/reset-password" passHref>
                    <span className="text-[#58D96B]">Click to Reset Password</span>
                  </Link>
                </p>
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </section>
  );
};

export default SignInWidget;


// const SignInWidget = ({ userGeo }) => {
//   const dispatch = useDispatch();
//   const authState = useSelector(login);
//   const router = useRouter();
//   const [eye, setEye] = useState(false);
//   const [loading, setLoading] = useState(false); // Loading state

//   const checkOnboardingProgress = async (authToken) => {
//     try {
//       const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/users/checkOnboardingProgress`, {
//         headers: { Authorization: `Bearer ${authToken}` },
//       });
//       return res.data.completed; // Assuming the API returns a "completed" field
//     } catch (error) {
//       toast.error('Failed to check onboarding progress');
//       return false; // Default to not completed in case of error
//     }
//   };

//   return (
//     <section className="">
//       <div className="bg-white">
//         <Card className="mt-10">
//           <header className="mb-8">
//             <Heading>
//               <p className="text-left font-[600] text-[#061938] lg:mt-0 mt-5 lg:text-[30px] leading-[30px] text-[20px]">
//                 Welcome Back!
//               </p>
//             </Heading>
//             <Subheading>
//               <p className="text-left py-2 text-[11px] lg:text-[16px]">
//                 {"Don't have an Account?"}
//                 <MainLink href="/auth/sign-up" text=" Sign Up" />
//               </p>
//             </Subheading>
//           </header>

//           <Formik
//             initialValues={{ email: '', password: '' }}
//             validationSchema={signInValidationSchema}
//             onSubmit={async (data) => {
//               dispatch(loginPending());

//               const formData = {
//                 ...data,
//                 geo: userGeo,
//               };

//               try {
//                 const res = await loginUser(formData);
//                 dispatch(loginSuccess(res.data));

//                 // Set loading state while checking onboarding progress
//                 setLoading(true);

//                 // Check if onboarding is completed
//                 const onboardingCompleted = await checkOnboardingProgress(res.data.authToken);

//                 if (onboardingCompleted) {
//                   // Redirect directly to affiliate dashboard if onboarding is completed
//                   router.push('/dashboard/affiliate');
//                 } else {
//                   // Redirect to stepper if onboarding is not completed
//                   router.push('/stepper');
//                 }
//               } catch (error) {
//                 toast.error(error.response?.data?.message || 'An error occurred');
//                 if (error.response?.status === 405) {
//                   router.push(`/auth/email-verify?email=${data?.email}`);
//                 }
//                 dispatch(loginFail());
//               } finally {
//                 setLoading(false); // Reset loading state
//               }
//             }}
//           >
//             {({ values, handleChange, handleSubmit, errors, touched }) => (
//               <form
//                 onSubmit={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px]">Email Address</p>
//                 <Input
//                   type="email"
//                   placeholder="Email"
//                   name="email"
//                   onChange={handleChange}
//                   error={errors.email && touched.email}
//                   value={values.email}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5]"
//                 />
//                 <ErrorMessage name="email">
//                   {(msg) => (
//                     <SmallText>
//                       <p className="error-text">{msg}</p>
//                     </SmallText>
//                   )}
//                 </ErrorMessage>

//                 <p className="text-[#061938] leading-[20px] text-[10px] lg:text-[13px] mt-4">Password</p>
//                 <Input
//                   type={eye ? 'text' : 'password'}
//                   icon={<Image src={eye ? eyeIcon : eyeOff} alt="eye icon" />}
//                   onPasswordToggle={() => setEye(!eye)}
//                   placeholder="Password"
//                   name="password"
//                   error={errors.password && touched.password}
//                   value={values.password}
//                   onChange={handleChange}
//                   className="border-[1px] rounded-[8px] border-[#B3BAC5] h-[60px]"
//                 />
//                 <ErrorMessage name="password">
//                   {(msg) => <p className="error-text text-xs">{msg}</p>}
//                 </ErrorMessage>

//                 <Button
//                   className="btn-primary my-7 py-6 text-[16px] lg:text-[20px] px-4 w-full"
//                   label={loading ? "Loading..." : "Log In"} // Change button text based on loading state
//                   type="submit"
//                   loading={authState.isLoading}
//                   disabled={loading} // Disable button while loading
//                 />
//                 <p
//                   className="text-[10px] lg:text-[12px] leading-[20px] font-[400]"
//                   style={{
//                     color: '#505F79',
//                     textAlign: 'left',
//                     cursor: 'pointer',
//                     marginTop: '8px',
//                   }}
//                 >
//                   Forgot Password ?{' '}
//                   <Link href="/auth/reset-password" passHref>
//                     <span className="text-[#58D96B]">Click to Reset Password</span>
//                   </Link>
//                 </p>
//               </form>
//             )}
//           </Formik>
//         </Card>
//       </div>
//     </section>
//   );
// };

// export default SignInWidget;
